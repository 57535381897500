import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	TemplateStatisticsDataType,
	TemplateStatisticsTotalSumType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';
const URL = process.env['NX_SUREM_EGRESS_API_URL'] || 'http://localhost:8080';
const getArlimTalkUsageAsync =
	(rootUrl: string) =>
	async ({ from, to, ...others }: GetArlimTalkUsageReqType) =>
		(
			await posAxiosClient.get<{
				data: TemplateStatisticsDataType[];
				totalSum: TemplateStatisticsTotalSumType;
			}>(`${rootUrl}/api/arlimtalk/usage`, {
				params: {
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
					...others,
				},
			})
		).data;

type GetArlimTalkUsageReqType = {
	from: Dayjs;
	to: Dayjs;
	enterpriseId: string;
	storeIdList?: number[];
	templateCode?: string;
};

export const ArlimTalkInfoApis = {
	getArlimTalkUsageInfo: getArlimTalkUsageAsync(URL),
};
