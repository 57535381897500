import { useMemo } from 'react';
import { useSessionGroupMapBySessionId } from './useSessionGroupMapBySessionId';
import { CartLineInfoType, CartLineProductInfoType } from '@kinderlabs-pos/shared-data-type';

export type RequiredField<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export const getSessionCartLines = (cartLines: CartLineInfoType[]) => {
	return (cartLines.filter((line) => line.type === 'PRODUCT') as CartLineProductInfoType[]).filter(
		(line) => line.sessionInfo
	) as RequiredField<CartLineProductInfoType, 'sessionInfo'>[];
};

export const getNoSessionCartLines = (cartLines: CartLineInfoType[]) => {
	return cartLines.filter(
		(line) =>
			line.type !== 'PRODUCT' ||
			(line.type === 'PRODUCT' && !(line as CartLineProductInfoType).sessionInfo)
	);
};

export const useGetSessionCartLinesWithSessionInfo = ({
	storeId,
	getKey = ({ sessionId }) => sessionId,
}: {
	storeId?: number;
	getKey?: (args: { sessionId: string }) => string;
}) => {
	const sessionGroupMapBySessionId = useSessionGroupMapBySessionId(storeId);

	return (cartLines: CartLineInfoType[]) => {
		const sessionCartLines = getSessionCartLines(cartLines).map((line) => {
			return {
				...line,
				fullSessionInfo: sessionGroupMapBySessionId[line.sessionInfo.sessionId].sessionInfo,
				sessionGroupInfo: sessionGroupMapBySessionId[line.sessionInfo.sessionId].sessionGroupInfo,
				sessionBoardInfo:
					sessionGroupMapBySessionId[line.sessionInfo.sessionId].sessionInfo.sessionBoard[
						line.sessionInfo.sessionBoardIndex
					],
			};
		});

		return sessionCartLines.reduce((acc, line) => {
			const key = getKey({ sessionId: line.sessionInfo.sessionId });

			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(line);
			return acc;
		}, {} as Record<string, typeof sessionCartLines>);
	};
};
