import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { SessionsApi } from '../apis';
import { AdminSessionGroupType, SessionLineType } from '../types';
import { useMemo } from 'react';

const sessionsQuery = createQueryKeyStore({
	sessions: {
		allForAdmin: (options: { storeIdList?: number[] }) => ({
			queryKey: [options],
			queryFn: async () => {
				return await SessionsApi.getAllForAdminAsync(options);
			},
		}),
		allForSale: (options: { storeId?: number }) => ({
			queryKey: [options],
			queryFn: async () => {
				if (!options.storeId) return [];
				return await SessionsApi.getAllForSalesAsync({
					storeId: options.storeId,
				});
			},
		}),
		allLines: (options: { storeId?: number }) => ({
			queryKey: [options],
			queryFn: async () => {
				if (!options.storeId) return [];
				return (
					(await SessionsApi.lines.getSessionLinesAsync({
						storeId: options.storeId,
					})) ?? []
				);
			},
		}),
	},
});

const useRefresh = () => {
	const queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries({
			queryKey: sessionsQuery.sessions._def,
		});
	};
};

const useOccupy = ({
	onSuccess,
	...others
}: Omit<
	UseMutationOptions<
		{
			message: string;
			sessionId: string;
			seq?: number;
		},
		unknown,
		{
			storeId: number;
			sessionId: string;
			userId: string;
			externalKey: string;
			capacity: number;
		},
		unknown
	>,
	'mutationFn'
>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (data) => {
			return await SessionsApi.lines.occupySessionLineAsync(data);
		},
		onSuccess: (data, variable, context) => {
			queryClient.invalidateQueries({
				queryKey: sessionsQuery.sessions._def,
			});
			onSuccess && onSuccess(data, variable, context);
		},
		...others,
	});
};

const useResolveByExternalKey = ({
	onSuccess,
	...others
}: Omit<
	UseMutationOptions<
		void,
		unknown,
		{
			storeId: number;
			sessionId: string;
			keyList: string[];
			includeOrdered: boolean;
		},
		unknown
	>,
	'mutationFn'
>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (data) => {
			if (data.keyList.length === 0) return;
			await SessionsApi.lines.resolveSessionLineByExternalKeyAsync(data);
		},
		onSuccess: (data, variable, context) => {
			queryClient.invalidateQueries({
				queryKey: sessionsQuery.sessions._def,
			});
			onSuccess && onSuccess(data, variable, context);
		},
		...others,
	});
};

const useResolveByUserId = ({
	onSuccess,
	...others
}: Omit<
	UseMutationOptions<
		SessionLineType[],
		unknown,
		{
			storeId: number;
			userId: string;
		},
		unknown
	>,
	'mutationFn'
>) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (data) => {
			return await SessionsApi.lines.resolveSessionLineByUserIdAsync(data);
		},
		onSuccess: (data, variable, context) => {
			queryClient.invalidateQueries({
				queryKey: sessionsQuery.sessions._def,
			});
			onSuccess && onSuccess(data, variable, context);
		},
		...others,
	});
};

const useSessionLinesMap = (options: { storeId: number }) => {
	const { data: 세션라인들 } = useQuery({
		...sessionsQuery.sessions.allLines(options),
		refetchInterval: 1000,
	});

	return useMemo(
		() =>
			(세션라인들 ?? []).reduce((acc, line) => {
				acc[line.sessionId] = [...(acc[line.sessionId] ?? []), line];
				return acc;
			}, {} as Record<string, SessionLineType[]>),
		[세션라인들]
	);
};

export const SessionsQuery = {
	query: sessionsQuery,
	useRefresh,
	lines: {
		useMap: useSessionLinesMap,
		useOccupy,
		useResolveByExternalKey,
		useResolveByUserId,
	},
};
