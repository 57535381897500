import { ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import {
	DeviceType,
	OrderReceiptRequestType,
	hardwareInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { AdminPosInfoState } from '../Admin/AdminPosInfoState';
import { OrderState, OrderStateType } from '../PosOrder/OrderState';
import { StoreInfoState } from '../StoreInfoState';
import { KioskElectronStoreState, PosElectronStoreState } from '../ElectronStoreState';

export const useKiosk주문증Printer = ({
	storeId,
	deviceType,
}: {
	storeId: number;
	deviceType: DeviceType;
}) => {
	const { receiptPrinterList: RPList, printMode } = StoreInfoState.useCurDeviceInfoForOrder();
	const deviceInfoList = AdminPosInfoState.usePosAndKioskSelectorList({ storeId });
	const localSettings =
		deviceType === 'POS'
			? PosElectronStoreState.usePosMainPageLocalSettings().data
			: KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	return {
		printKiosk주문서Async: async ({ order }: { order: OrderStateType }) => {
			const deviceInfo = deviceInfoList.find((d) => d.device.id === order.posId);
			const orderRequest = OrderState.cart.utils.getOrderListFromCart(order.cart.lines, 'default');

			const CandidatePrinterInfos = RPList.filter(
				(p) => p.hardwareType.usageType === 'NORMAL' && p.isActive
			);

			for (const printerInfo of CandidatePrinterInfos) {
				for (let i = 0; i < printerInfo.quantity; i++) {
					await printKioskReceipt({
						hardwareInfo: {
							deviceType: deviceType,
							printerInfo: printerInfo,
							printMode,
							영수증너비: localSettings ? localSettings.영수증너비 : deviceType === 'POS' ? 42 : 48,
						},
						orderDeviceName: deviceInfo ? `[${deviceInfo.type}] ${deviceInfo.device.name}` : '-',
						orderRequest,
						dailySeq: order.dailySeq ?? 0,
					});
				}
			}
		},
	};
};

// JIN
const printKioskReceipt = async ({
	hardwareInfo,
	orderDeviceName,
	orderRequest,
	dailySeq,
}: {
	hardwareInfo: hardwareInfoType;
	orderDeviceName: string;
	orderRequest: OrderReceiptRequestType[];
	dailySeq: number;
}) => {
	await ReactRPIPC.invoke키오스크주문증출력({
		hardwareInfo,
		order: orderRequest,
		orderDeviceName,
		orderDailySeq: dailySeq,
		orderDate: new Date(),
	});
};
