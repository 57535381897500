import {
	CartLineInfoType,
	CartLineOnlineTicketOnoffmixInfoType,
	CartLineTypeRules,
	CartType,
	OrderReceiptRequestType,
	ProductInfoType,
	getAggregate,
} from '@kinderlabs-pos/shared-data-type';
import { useState } from 'react';
import { CartLineBellActionType, CartLineBellState } from './CartLineBellState';
import {
	CartBoardActionType,
	CartLineState,
	calculate분할결제를고려한_실결제상청구할VAT,
} from './CartLineBoardState';
import { CartLineCustomActionType, CartLineCustomState } from './CartLineCustomState';
import { CartLineDiscountActionType, CartLineDiscountState } from './CartLineDiscountState';
import { CartLineExcessChargeState, ExcessChargeAction } from './CartLineExcessChargeState';
import { CartLinePosCouponActionType, CartLinePosCouponState } from './CartLineKlCouponState';
import {
	CartLineOnlineTicketOnoffmixActionType,
	CartLineOnlineTicketState,
} from './CartLineOnlineTicketState';
import { CartLineProductActionType, CartLineProductState } from './CartLineProductState';
import { CartLineTicketUsageActionType, CartLineTicketUsageState } from './CartLineTicketState';
import {
	CartLineSessionProductActionType,
	CartLineSessionProductState,
} from './CartLineSessionProductState';
import { OrderState } from '..';

const getOrderListFromCartForReceipt = (
	cartLines: CartLineInfoType[],
	mode: 'default' | 'cafe' | 'kitchen'
): (OrderReceiptRequestType & { cartLineId: string; targetCartLineId?: string })[] => {
	let cartLinesToRender: CartLineInfoType[] = [];

	switch (mode) {
		case 'default':
			cartLinesToRender = CartLineTypeRules.sortCartLines(
				OrderState.cart.lines.get세션고려Lines(cartLines)
			);
			break;
		case 'cafe':
			cartLinesToRender = CartLineTypeRules.sortCartLines(
				cartLines.filter((cl) => CartLineTypeRules.canShowOn프론트영수증[cl.type])
			);
			break;
		case 'kitchen':
			cartLinesToRender = CartLineTypeRules.sortCartLines(
				cartLines.filter((cl) => CartLineTypeRules.canShowOn주방영수증[cl.type])
			);
			break;
	}

	return cartLinesToRender.map((cartLine, idx, cartLineList) => {
		const isOption = CartLineTypeRules.isOptionsInCart[cartLine.type];
		const isBell = cartLine.type === 'BELL';

		const isForKitchen =
			cartLine.type === 'SET_PRODUCT' ||
			(cartLine.type === 'PRODUCT' && cartLine.productInfo.isForKitchen) ||
			(cartLine.type === 'SET_OPTION' && cartLine.productInfo.isForKitchen) ||
			(cartLine.type === 'OPTION' &&
				cartLineList.find((cl) => cl.id === cartLine.targetCartLineId)?.productInfo.isForKitchen) ||
			false;

		const cartLineName = isOption
			? ` └ ${cartLine.name}`
			: isBell
			? `${cartLine.name}`
			: cartLine.type === 'ONLINE_TICKET_ONOFFMIX'
			? `${cartLine.name} (#${
					(cartLine as CartLineOnlineTicketOnoffmixInfoType).ticketUsageInfo.ticketUsageId
			  })`
			: `${cartLine.name}`;

		return {
			type: cartLine.type,
			name: cartLineName,
			quantity: isBell || isOption ? undefined : cartLine.quantity,
			unitPrice: cartLine.price,
			totalPrice: cartLine.price * cartLine.quantity,
			isForKitchen: isForKitchen,
			cartLineId: cartLine.id,
			targetCartLineId: cartLine.targetCartLineId,
			origin: cartLine,
		};
	});
};

/**
 * 주방 영수증 여부 확인
 */
const hasKitchenReceipt = (orderCartLines: CartLineInfoType[]) => {
	return orderCartLines.some((ocl) => ocl.productInfo.isForKitchen);
};

export type CartActionType =
	| { type: 'CLEAR' }
	| { type: 'BOARD'; subAction: CartBoardActionType }
	| { type: 'USAGES'; subAction: CartLineTicketUsageActionType }
	| { type: 'CUSTOM'; subAction: CartLineCustomActionType }
	| { type: 'PRODUCTS'; subAction: CartLineProductActionType }
	| { type: 'SESSION_PRODUCT'; subAction: CartLineSessionProductActionType }
	| { type: 'DISCOUNTS'; subAction: CartLineDiscountActionType }
	| { type: 'EXCESS_CHARGE'; subAction: ExcessChargeAction }
	| { type: 'BELL'; subAction: CartLineBellActionType }
	| { type: 'KL_COUPON'; subAction: CartLinePosCouponActionType }
	| { type: 'ONLINE_TICKET_ONOFFMIX'; subAction: CartLineOnlineTicketOnoffmixActionType }
	// 자주 쓸것 같지는 않음.
	| { type: 'CLEAR_DISCOUNTS' }
	| { type: 'REPARE_CARTLINES'; newCartLines: CartLineInfoType[] };

const CartReducer = (prev: CartType, action: CartActionType): CartType => {
	switch (action.type) {
		case 'CLEAR':
			return {
				lines: [],
			};
		case 'BOARD':
			return {
				...prev,
				lines: CartLineState.reducer(prev.lines, action.subAction),
			};
		case 'PRODUCTS':
			return {
				...prev,
				lines: CartLineProductState.reducer(prev.lines, action.subAction),
			};
		case 'DISCOUNTS':
			return {
				...prev,
				lines: CartLineDiscountState.reducer(prev.lines, action.subAction),
			};
		case 'EXCESS_CHARGE':
			return {
				...prev,
				lines: CartLineExcessChargeState.reducer(prev.lines, action.subAction),
			};
		case 'USAGES':
			return {
				...prev,
				lines: CartLineTicketUsageState.reducer(prev.lines, action.subAction),
			};
		case 'CUSTOM':
			return { ...prev, lines: CartLineCustomState.reducer(prev.lines, action.subAction) };
		case 'BELL':
			return { ...prev, lines: CartLineBellState.reducer(prev.lines, action.subAction) };
		case 'KL_COUPON':
			return { ...prev, lines: CartLinePosCouponState.reducer(prev.lines, action.subAction) };
		case 'CLEAR_DISCOUNTS': {
			const newLines = CartLineCustomState.reducer(prev.lines, { type: 'CLEAR_DISCOUNTS' });
			return {
				...prev,
				lines: CartLineTicketUsageState.reducer(newLines, { type: 'CLEAR_DISCOUNTS' }),
			};
		}
		case 'ONLINE_TICKET_ONOFFMIX':
			return {
				...prev,
				lines: CartLineOnlineTicketState.reducer(prev.lines, action.subAction),
			};
		case 'REPARE_CARTLINES':
			return {
				...prev,
				lines: action.newCartLines,
			};
		case 'SESSION_PRODUCT':
			return {
				...prev,
				lines: CartLineSessionProductState.reducer(prev.lines, action.subAction),
			};
	}
};

const isEmpty = (cart: CartType) => cart.lines.length === 0;

const useSetProductOptionSelectMap = ({
	productInfo,
	initialSelect,
}: {
	productInfo: ProductInfoType;
	initialSelect: boolean;
}) => {
	const [selectedOptionMap, setSelectedOptionMap] = useState<{
		[optionGroupId: number]: number[];
	}>(
		(productInfo?.optionGroupList || []).reduce(
			(arr, og) => {
				arr[og.id] = initialSelect
					? og.optionList.filter((_, idx) => idx < og.minSelect).map((option) => option.id)
					: [];
				return arr;
			},
			{} as {
				[optionGroupId: number]: number[];
			}
		)
	);

	const handleSelectedOptionIdList = (optionGroupId: number) => (optionList: number[]) => {
		setSelectedOptionMap((prev) => {
			return { ...prev, [optionGroupId]: optionList };
		});
	};

	const validMap = (productInfo?.optionGroupList || []).reduce((arr, optionGroup) => {
		arr[optionGroup.id] =
			selectedOptionMap[optionGroup.id].length >= optionGroup.minSelect &&
			selectedOptionMap[optionGroup.id].length <= optionGroup.maxSelect;
		return arr;
	}, {} as { [optionGroupId: number]: boolean });

	const isValid = (productInfo?.optionGroupList || []).every(
		(optionGroup) => validMap[optionGroup.id]
	);

	return { selectedOptionMap, setSelectedOptionMap, handleSelectedOptionIdList, validMap, isValid };
};

/**
 * JIN : 조금 애매한데, optionId를 추가할때 '선택' 시 LIFO 방식으로 List 반환. minSelect 에 의해 데이터를 나눔
 * @param param0
 * @returns
 */
const useLIFOIdList = ({
	maxSelect,
	selectedIdList,
}: {
	maxSelect: number;
	selectedIdList: number[];
}) => {
	return (optionId: number) => {
		if (maxSelect > selectedIdList.length) return [...selectedIdList, optionId];
		else
			return [
				...selectedIdList.filter((_, index) => index !== selectedIdList.length - 1),
				optionId,
			];
	};
};

const 결산불가능 = (cart: CartType) =>
	cart.lines.some((cl) => !CartLineTypeRules.is결산가능[cl.type]);

export const CartState = {
	reducer: CartReducer,
	utils: {
		getOrderListFromCart: getOrderListFromCartForReceipt,
		getAggregate,
		isEmptyCart: isEmpty,
		결산불가능,
		hasKitchenReceipt,
		useSetProductOptionSelectMap,
		useLIFOIdList,
		calculate분할결제를고려한_실결제상청구할VAT,
	},
};
