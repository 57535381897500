import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { vaunceAppColors } from '@kinderlabs-pos/shared-const';
import { oneTicketInfo, PlaceList, postTicketInfo } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { RoundedButton, RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import {
	Box,
	Button,
	ButtonGroup,
	FormControl,
	FormControlLabel,
	InputLabel,
	NativeSelect,
	Stack,
	styled,
	Typography,
} from '@mui/material';
import ticketImage from 'assets/vaunce/image/multi_large.png';
import React, { useEffect, useRef, useState } from 'react';
import { NicePayPage } from '../../components/NicePay';
import { PageHeader } from '../../components/PageHeader';
import { RoundBox } from '../../components/RoundBox';
import { VaunceCheckBox } from '../../components/VaunceCheckBox';
import { AgreeAllInfomationBtn } from '../MultiTicketPage';
import { useAtom } from 'jotai';

const noticeFirstContent = [
	'구매 시 선택한 센터에서만 사용이 가능합니다.',
	'센터 별 키 또는 나이에 따라 요금이 다르니 실제 이용자에 맞게 구매하시기 바랍니다.',
	'주중 이용권은 주말 및 공휴일에 사용이 불가합니다.',
	'유효기간 이내 환불 시, 100% 환불 가능합니다.',
	'유효기간 이후 환불 시, 90% 환불 가능합니다.',
];

const noticeSecondContent = [
	'이용자의 키 또는 나이가 구매한 이용권과 맞지 않는 경우 사용이 불가하며 현장에서 재구매가 필요합니다.',
];

export interface ITicketsalePageProps {}
export const TicketsalePage: React.FC<ITicketsalePageProps> = ({}) => {
	const [oneTicketCount] = useAtom(VaunceQueryState.OneTicketCountAtom);

	const [checked, setChecked] = React.useState([false, false]);

	const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([event.target.checked, event.target.checked, event.target.checked]);
	};

	const handleChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([event.target.checked, checked[1], checked[2]]);
	};

	const handleChangeSecond = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([checked[0], event.target.checked, checked[2]]);
	};

	const myInfo = VaunceQueryState.useMyInfoQuery();

	const oneTicketInfo = VaunceQueryState.useTicketInfoQuery('PREMIUM_ONE');

	const [centerId, setCenterId] = useState<number>(0);
	const [priceDisplayName, setPriceDisplayName] = useState<string>('unSelected');
	const [price, setPrice] = useState<string>('');
	const [placeList, setPlaceList] = useState<PlaceList[]>([]);

	const ref = useRef<any>();

	const [hashString, setHashString] = useState<string>('');
	const [ediDate, setEdiDate] = useState<string>('');
	const [payModel, setPayModel] = useState<any>({});

	function getCenterName(displayName: string) {
		return displayName.split('_')[3];
	}

	function getCenter(centerId: number) {
		const findCenter = oneTicketInfo.listCenters.find((elem) => elem.centerId === centerId);
		// let newCenter = `멀티_${findCenter?.centerDisplay}_${count}회권_${getCenterName(priceDisplayName)}`;
		const newCenter = `1회권_${findCenter?.centerDisplay}`;
		return newCenter;
	}

	function convertNumberPrice(price: string) {
		return Number(price.replace(',', ''));
	}

	const handlePay = async () => {
		const ticketList: oneTicketInfo[] = [];
		placeList.map((place) => {
			for (let i = 0; i < place.count; i++) {
				const ticketSingle: oneTicketInfo = {
					webTicketMasterListIdx: place.web_ticket_master_list_idx,
					price: convertNumberPrice(place.price),
					count: i.toString(),
					goodsName: `1회권_${place.center}${getCenterName(place.priceDisplayName)}${
						place.price
					}_${(i + 1).toString()}`,
				};
				ticketList.push(ticketSingle);
			}
		});

		if (placeList[0] && placeList[0].center && myInfo) {
			const ticketInfo: postTicketInfo = {
				centerId: centerId,
				gUserId: myInfo.userId,
				totalPrice: getTotalPrice(),
				centerName: placeList[0].center ?? '',
				list: ticketList,
			};
			const currentTicketTotalCount = placeList.reduce((acc, cur) => acc + cur.count, 0);

			// if (currentTicketTotalCount + oneTicketCount > 10) {
			// 	alert('계정당 10개 이상 이용권을 구입할 수 없습니다.');
			// } else {
			// 	postNicePayApi(ticketInfo);
			// }
			postNicePayApi(ticketInfo);
		}
	};

	const postNicePayApi = async (ticketInfo: postTicketInfo) => {
		const data = await VaunceAppApis.postNicePayOneAsync(
			encodeURIComponent(JSON.stringify(ticketInfo))
		);
		if (data) {
			setHashString(data.hashString);
			setEdiDate(data.ediDate);
			setPayModel(data.payRequestModel);
		}
	};

	const handleTicketCount = (selectedIdx: number, count: number) => {
		setPlaceList(
			placeList.map((place, idx) =>
				idx === selectedIdx
					? {
							...place,
							count: place.count + count,
							totalPrice: convertNumberPrice(place.price) * (place.count + count),
					  }
					: place
			)
		);
	};

	const handleTicketType = (ticketType: string) => {
		setPriceDisplayName(ticketType);
		const findCenter = oneTicketInfo.listCenters.find((elem) => elem.centerId === centerId);
		const findTicketDetailInfo = findCenter?.listPerson.find(
			(person) => person.listPrice[0].priceDisplayName === ticketType
		);

		if (findTicketDetailInfo) {
			const displayNameList = placeList.map((elem) => elem.priceDisplayName);
			if (!displayNameList.includes(findTicketDetailInfo.listPrice[0].priceDisplayName)) {
				setPlaceList([
					...placeList,
					{
						...findTicketDetailInfo.listPrice[0],
						center: findCenter?.centerDisplay,
						count: 1,
						totalPrice: convertNumberPrice(findTicketDetailInfo.listPrice[0].price),
					},
				]);
			}
		}
	};

	const deletePlace = (selectIdx: number) => {
		setPlaceList(placeList.filter((child, idx) => idx !== selectIdx));
	};

	function getTotalPrice(): number {
		let totalSum = 0;
		placeList.map((place) => {
			totalSum += place.totalPrice;
		});
		return totalSum;
	}

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	useEffect(() => {
		if (hashString && ref.current) ref.current.submit();
	}, [ref, hashString]);

	const children = (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<ContentBox>
				<CheckLabelBox
					sx={{ fontSize: '1.5em' }}
					label={<Typography variant='h5'>[필수] 이용권 구매에 동의</Typography>}
					control={
						<VaunceCheckBox
							checked={checked[0]}
							onChange={handleChangeFirst}
							size={'small'}
						/>
					}
				/>
			</ContentBox>

			<ContentBox>
				<CheckLabelBox
					sx={{ fontSize: '16px' }}
					label={<Typography variant='h5'>[필수] 결제, 환불 및 사용 유의사항에 동의</Typography>}
					control={
						<VaunceCheckBox
							checked={checked[1]}
							onChange={handleChangeSecond}
							size={'small'}
						/>
					}
				/>
			</ContentBox>
		</Box>
	);

	return (
		<Stack
			height={'100%'}
			direction={'column'}>
			<PageHeader title='이용권' />
			<Stack
				height={'100%'}
				overflow={'auto'}
				direction={'column'}
				textAlign={'center'}
				alignItems={'center'}>
				<Box marginBottom={'1.5rem'}>
					<img
						src={ticketImage}
						width={70}
					/>
				</Box>

				<Typography
					variant='h2'
					color='primary'>
					바운스 2시간 이용권
				</Typography>
				{/* <Typography
					variant='h4'
					color={'#707070'}>
					[특가 기간] 4/29 월 ~ 4/30 화
				</Typography> */}
				<Typography
					variant='h4'
					color={'#707070'}>
					유효기간: 4월 30일 (수) 까지
				</Typography>

				<RoundBox
					marginTop={'3em'}
					textAlign={'left'}>
					<Typography variant='h5'>
						- APP 한정 특가가 적용된 금액입니다. (평일 20% / 주말 및 공휴일 10%)
					</Typography>
					<Typography variant='h5'>- 보호자 입장권은 현장 구매 가능합니다.</Typography>
					<Typography variant='h5'>
						- 미사용 건은 유효기간 만료 전 취소 시, 100% 환불 가능합니다. (My페이지 → 고객센터 →
						이용권 환불)
					</Typography>
				</RoundBox>

				<Stack
					spacing={3}
					width={'100%'}>
					<FormControl
						fullWidth
						sx={{ pt: 2 }}>
						<InputLabel variant='standard'>
							<Typography variant='h2'> 선택1 (이용센터)</Typography>
						</InputLabel>
						<NativeSelect
							sx={{ fontSize: '16px', fontWeight: 500 }}
							value={centerId}
							onChange={(event) => {
								setCenterId(parseInt(event?.target.value));
								setPlaceList([]);
								setPriceDisplayName('unSelected');
							}}>
							<option value={'unSelected'}>{'선택'}</option>
							{oneTicketInfo &&
								oneTicketInfo.listCenters.map((centerInfo) => (
									<option
										value={centerInfo.centerId}
										key={centerInfo.centerId}>
										{centerInfo.centerDisplay}
									</option>
								))}
						</NativeSelect>
					</FormControl>

					<FormControl
						fullWidth
						sx={{ pt: 2 }}>
						<InputLabel variant='standard'>
							<Typography variant='h2'> 선택2 (권종)</Typography>
						</InputLabel>
						<NativeSelect
							sx={{ fontSize: '16px', fontWeight: 500 }}
							value={priceDisplayName}
							onChange={(event) => {
								handleTicketType(event.target.value);
							}}>
							<option value={'unSelected'}>{'선택'}</option>
							{oneTicketInfo.listCenters
								.find((elem) => elem.centerId === centerId)
								?.listPerson.map((elem) => (
									<option
										value={elem.listPrice[0].priceDisplayName}
										key={elem.personTypeId}>
										{getCenterName(elem.listPrice[0].priceDisplayName)}
									</option>
								))}
						</NativeSelect>
					</FormControl>

					<Stack spacing={3}>
						{placeList.length > 0 &&
							placeList.map((place, idx) => (
								<Stack
									sx={{ borderBottom: 'dotted #00000066' }}
									spacing={1}
									py={1}>
									<Stack
										direction={'row'}
										spacing={1}>
										<Stack
											direction={'column'}
											flex={1}
											alignItems={'flex-start'}>
											<Typography
												variant='h5'
												textAlign={'left'}
												fontSize={'12px'}>
												[특가]{place.center}
											</Typography>
											<Typography
												variant='h5'
												fontSize={'12px'}>
												{getCenterName(place.priceDisplayName)}
											</Typography>
											<Typography variant='h3'>{place.price}원</Typography>
										</Stack>

										<ButtonGroup
											disableElevation
											sx={{ height: 38 }}>
											<Button
												onClick={() => {
													handleTicketCount(idx, -1);
												}}>
												-
											</Button>
											<Stack
												width={40}
												justifyContent={'center'}
												border={`1px solid ${vaunceAppColors.lightPink}`}>
												<Typography variant='h3'>{place.count}</Typography>
											</Stack>

											<Button
												variant='contained'
												onClick={() => {
													handleTicketCount(idx, +1);
												}}>
												+
											</Button>
										</ButtonGroup>
									</Stack>
									<Stack
										direction={'row'}
										justifyContent={'space-between'}>
										<Typography
											color={'primary'}
											variant='h5'
											onClick={() => {
												deletePlace(idx);
											}}>
											x 삭제
										</Typography>
										<Typography variant='h3'>{place.totalPrice.toLocaleString()}원</Typography>
									</Stack>
								</Stack>
							))}
					</Stack>

					<FormControl
						fullWidth
						sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.42)' }}>
						<Stack direction={'row'}>
							<FormControlLabel
								label={<Typography variant='h5'>[필수] 모두 동의합니다.</Typography>}
								control={
									<VaunceCheckBox
										checked={checked[0] && checked[1]}
										onChange={handleChangeAll}
									/>
								}
								sx={{ mr: 'auto', fontSize: '1.4em' }}
							/>
							<AgreeAllInfomationBtn
								noticeFirstContent={noticeFirstContent}
								noticeSecondContent={noticeSecondContent}
							/>
						</Stack>
						{children}
					</FormControl>

					<Stack
						direction={'row'}
						justifyContent={'space-between'}>
						<Typography variant='h2'>결제 금액</Typography>
						<Typography variant='h1'>{getTotalPrice().toLocaleString()}원</Typography>
					</Stack>
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={2}
						p={1}>
						<RoundedSubmitButton
							fullWidth
							onClick={() => {
								handlePay();
								setIsSubmitting(true);
								setTimeout(() => {
									setIsSubmitting(false);
								}, 3000);
							}}
							disabled={
								!(checked[0] && checked[1] && centerId && priceDisplayName !== 'unSelected')
							}
							isSubmitting={isSubmitting}
							text={'구매하기'}
						/>
					</Stack>

					<NicePayPage
						payRef={ref}
						payModel={payModel}
						ediDate={ediDate}
						hashString={hashString}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

const CheckLabelBox = styled(FormControlLabel)(({ theme }) => ({
	marginRight: 'auto',
}));

const ContentBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
}));
