import { ReactPMIPC } from '@kinderlabs-pos/ipc/react';
import {
	APPCardPaymentInfoType,
	CardMSPaymentInfoType,
	CardPaymentInfoType,
	CashICPaymentInfoType,
	CashPaymentInfoType,
	CreditPaymentInfoType,
	GiftCardPaymentInfoType,
	GiftCardType,
	KakaoPayPaymentInfoType,
	PointUseInfoType,
	SeoulPayPaymentInfoType,
	SeoulPayMPMPaymentInfoType,
	SSGPayPaymentInfoType,
	TransferPaymentInfoType,
	VanCompanyType,
	VirtualCardPaymentInfoType,
	ZeroPayMPMPaymentInfoType,
	ZeroPayPaymentInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { OrderState, OrderStateType, StoreInfoState } from '@kinderlabs-pos/state';
import { useHandle결제모듈 } from './handlePaymentByPM';

type 할부가능 = { installment: number };
type CustomerId필요 = { customerId: string };
type 메모필요 = { memo: string };
type 메모가능 = { memo?: string };
type CustomerType필요 = { isEnterprise: boolean };
type SignPadUsingType필요 = { isSignPadUsed: boolean };
type memberId필요 = { memberId: number };
type 상품권필요Data = {
	giftCard: GiftCardType;
};

export type HandlePaymentArgsType = { amount: number } & (
	| { method: 'CASH_현금영수증_미발급'; receivedMoney: number }
	| { method: 'CASH_IC' }
	| { method: 'ZEROPAY_MPM' | 'SEOULPAY_MPM' }
	| ({ method: 'CARD' | 'CARD_MS' } & 할부가능)
	| ({ method: 'VIRTUAL_CARD' } & 할부가능 & 메모가능)
	| ({ method: 'CASH_현금영수증_발급'; receivedMoney: number } & CustomerId필요 &
			CustomerType필요 &
			SignPadUsingType필요)
	| ({ method: 'TRANSFER_현금영수증_발급' } & CustomerId필요 &
			CustomerType필요 &
			SignPadUsingType필요 &
			메모필요)
	| ({ method: 'GIFTCARD_현금영수증_발급' } & CustomerId필요 &
			CustomerType필요 &
			SignPadUsingType필요 &
			상품권필요Data)
	| ({
			method:
				| 'KAKAOPAY'
				| 'SSGPAY'
				| 'APPCARD'
				| 'ALIPAY'
				| 'WECHATPAY'
				| 'NAVERPAY'
				| 'BCPAYBOOC'
				| 'PAYCO';
	  } & CustomerId필요 &
			할부가능)
	| ({ method: 'SEOULPAY' | 'ZEROPAY' } & CustomerId필요)
	| ({ method: 'TRANSFER_현금영수증_미발급' | 'CREDIT' } & 메모필요)
	| ({ method: 'GIFTCARD_현금영수증_미발급' } & 상품권필요Data)
	| ({ method: 'POINT' } & memberId필요)
);

type HandlePaymentOptionType = {
	storeId: number;
	orderId?: string;
	posId: number;
};

export const isOfficialPaymentPayload: Record<HandlePaymentArgsType['method'], boolean> = {
	CASH_IC: true,
	CARD: true,
	CARD_MS: true,
	CASH_현금영수증_발급: true,
	TRANSFER_현금영수증_발급: true,
	GIFTCARD_현금영수증_발급: true,
	CASH_현금영수증_미발급: false,
	TRANSFER_현금영수증_미발급: false,
	GIFTCARD_현금영수증_미발급: false,
	ZEROPAY_MPM: false,
	SEOULPAY_MPM: false,
	VIRTUAL_CARD: false,
	CREDIT: false,
	POINT: false,
	KAKAOPAY: true,
	SSGPAY: true,
	SEOULPAY: true,
	APPCARD: true,
	ALIPAY: true,
	WECHATPAY: true,
	NAVERPAY: true,
	BCPAYBOOC: true,
	ZEROPAY: true,
	PAYCO: true,
};

// const cachedPaymentRequestAtom = atom<
// 	| {
// 			payload: HandlePaymentArgsType;
// 			result: PaymentRequestInfoType;
// 			processedDate: Dayjs;
// 	  }
// 	| undefined
// >(undefined);

// JIN 2
export const useHandlePaymentPM = (options: HandlePaymentOptionType) => {
	const { vanCompany } = StoreInfoState.useCurDeviceInfoForOrder();

	const handle결제모듈 = useHandle결제모듈(vanCompany);

	return async (payload: HandlePaymentArgsType, orderInfo: OrderStateType) => {
		const { taxFreeAmount, tax } =
			OrderState.cart.utils.calculate분할결제를고려한_실결제상청구할VAT({
				paymentAmount: payload.amount,
				order: orderInfo,
			});

		switch (payload.method) {
			case 'CARD':
			case 'CARD_MS':
			case 'CASH_IC':
				const 카드결제모듈처리Data = await handle결제모듈({
					...payload,
					tax,
				});

				const cardIcResponse: CardPaymentInfoType | CardMSPaymentInfoType | CashICPaymentInfoType =
					{
						method: payload.method,
						created: new Date(),
						amount: payload.amount,
						taxFreeAmount: taxFreeAmount,
						tax: tax,
						receipt: {
							...카드결제모듈처리Data,
							receiptType: 'BUY',
						},
						...options,
					};
				return cardIcResponse;
			case 'CASH_현금영수증_미발급':
				const cashResponse: CashPaymentInfoType = {
					method: 'CASH',
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					receivedMoney: payload.receivedMoney,
					...options,
				};
				return cashResponse;
			case 'CASH_현금영수증_발급':
				const 현금영수증_발급Data = await handle결제모듈({
					...payload,
					tax,
				});

				const cash현금영수증Response: CashPaymentInfoType = {
					method: 'CASH',
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					receivedMoney: payload.receivedMoney,
					receipt: {
						...현금영수증_발급Data,
						customerId: 현금영수증_발급Data.customerId,
						receiptType: 'BUY',
					},
					...options,
				};
				return cash현금영수증Response;
			case 'TRANSFER_현금영수증_발급':
				const 계좌이체_현금영수증_발급Data = await handle결제모듈({
					...payload,
					tax,
				});
				const 계좌이체_현금영수증발급_Response: TransferPaymentInfoType = {
					method: 'TRANSFER',
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					receipt: {
						...계좌이체_현금영수증_발급Data,
						customerId: 계좌이체_현금영수증_발급Data.bin,
						receiptType: 'BUY',
					},
					memo: payload.memo,
					...options,
				};
				return 계좌이체_현금영수증발급_Response;
			case 'GIFTCARD_현금영수증_발급':
				const 상품권_현금영수증_발급Data = await handle결제모듈({
					...payload,
					tax,
				});
				const 상품권_현금영수증_발급_Response: GiftCardPaymentInfoType = {
					method: 'GIFTCARD',
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					receipt: {
						...상품권_현금영수증_발급Data,
						customerId: 상품권_현금영수증_발급Data.bin,
						receiptType: 'BUY',
					},
					giftCardProvider: payload.giftCard.giftCardProvider,
					giftCardName: payload.giftCard.giftCardName,
					giftCardNumber: payload.giftCard.giftCardNumber,
					...options,
				};

				return 상품권_현금영수증_발급_Response;
			case 'VIRTUAL_CARD': {
				const 가상카드_Response: VirtualCardPaymentInfoType = {
					method: 'VIRTUAL_CARD',
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					memo: payload.memo,
					receipt: undefined,
					...options,
				} as VirtualCardPaymentInfoType;
				return 가상카드_Response;
			}
			case 'KAKAOPAY':
			case 'APPCARD':
			case 'SSGPAY':
			case 'SEOULPAY':
			case 'ALIPAY':
			case 'WECHATPAY':
			case 'NAVERPAY':
			case 'BCPAYBOOC':
			case 'ZEROPAY':
			case 'PAYCO':
				const 간펼결제모듈처리Data = await handle결제모듈({
					...payload,
					tax,
				});
				return {
					method: payload.method,
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					receipt: {
						...간펼결제모듈처리Data,
						receiptType: 'BUY',
					},
					...options,
				} as KakaoPayPaymentInfoType;
			case 'TRANSFER_현금영수증_미발급':
				const 계좌이체_Response: TransferPaymentInfoType = {
					method: 'TRANSFER',
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					memo: payload.memo,
					...options,
				};
				return 계좌이체_Response;
			case 'GIFTCARD_현금영수증_미발급':
				const 상품권_Response: GiftCardPaymentInfoType = {
					method: 'GIFTCARD',
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					giftCardProvider: payload.giftCard.giftCardProvider,
					giftCardName: payload.giftCard.giftCardName,
					giftCardNumber: payload.giftCard.giftCardNumber,
					...options,
				};

				return 상품권_Response;
			case 'CREDIT':
				const 외상_Response: CreditPaymentInfoType = {
					method: payload.method,
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					memo: payload.memo,
					receipt: undefined,
					...options,
				};

				return 외상_Response;
			case 'ZEROPAY_MPM':
			case 'SEOULPAY_MPM':
				const 제로페이Response: ZeroPayMPMPaymentInfoType | SeoulPayMPMPaymentInfoType = {
					method: payload.method,
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					receipt: undefined,
					...options,
				};
				return 제로페이Response;
			case 'POINT':
				const pointResponse: PointUseInfoType = {
					method: payload.method,
					created: new Date(),
					amount: payload.amount,
					taxFreeAmount: taxFreeAmount,
					tax: tax,
					memberId: payload.memberId,
					receipt: undefined,
					...options,
				};
				return pointResponse;
			default:
				throw Error('[TypeError] 결제 모듈 타입이 없습니다.');
		}
	};
};

export const useHandlePaymentPM강제취소 = () => {
	const { vanCompany } = StoreInfoState.useCurDeviceInfoForOrder();

	return async () => {
		return await ReactPMIPC.invoke결제강제취소({
			vanCompany: vanCompany,
		});
	};
};

export const useHandle카드정보가져오기 = () => {
	const { vanCompany } = StoreInfoState.useCurDeviceInfoForOrder();

	return async () => {
		return await ReactPMIPC.invoke카드BIN요청({
			vanCompany: vanCompany,
		});
	};
};

export const useHandle카드삽입여부확인하기 = ({ vanCompany }: { vanCompany: VanCompanyType }) => {
	return async () => {
		return await ReactPMIPC.invoke카드삽입여부확인요청({
			vanCompany,
		});
	};
};
