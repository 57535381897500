import { KioskLocalSettingsType, ipcKioskApiName } from '@kinderlabs-pos/ipc/common';
import { HAS_NO_ELECTRON, isOnElectron } from './const';

const saveSettings = (localSettings: KioskLocalSettingsType) =>
	isOnElectron
		? window[ipcKioskApiName].localSettings.invokeSaveLocalSettings(localSettings)
		: HAS_NO_ELECTRON;

const loadSettings = (): Promise<KioskLocalSettingsType> =>
	isOnElectron
		? window[ipcKioskApiName].localSettings.invokeLoadLocalSettings()
		: Promise.resolve({
				recentBells: [],
				recentLoginInfo: undefined,
				kioskInfo: undefined,
				isMemberInfoRequiredForFABKiosk: false,
				hideSoldOutItems: false,
				receiptAutoPrint: false,
				showScrollButtons: false,
				skipMembership: false,
				onlineTicketReceiptMessage: { kr: '', eng: '' },
				onlineTicketMessage: { kr: '', eng: '' },
				usingBarcodeModel: 'QRCODE',
				i18n: {
					eng: false,
					jpn: false,
					chn: false,
				},
				카드삽입여부검사: false,
				영수증너비: 48,
		  });

export const ReactKioskMainPageIPC = {
	localSettings: {
		saveSettings,
		loadSettings,
	},
};
