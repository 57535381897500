import { ConnectInfoType, DeviceType } from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../const';
import { deviceAxiosClient, posAxiosClient } from '@kinderlabs-pos/apis/axios';

const getConnectInfoListByType =
	(rootUrl: string) =>
	async ({ storeId, deviceType }: { storeId: number; deviceType: DeviceType }) =>
		(
			await posAxiosClient.get<ConnectInfoType[]>(
				`${rootUrl}/connect/store/${storeId}/type/${deviceType}`,
				{}
			)
		).data;

const disconnectDevice =
	(rootUrl: string) =>
	async ({
		storeId,
		deviceId,
		deviceType,
	}: {
		storeId: number;
		deviceId: number;
		deviceType: DeviceType;
	}) => {
		const result = await deviceAxiosClient.post<ConnectInfoType[]>(
			`${rootUrl}/disconnect/store/${storeId}/type/${deviceType}`,
			{},
			{
				params: {
					deviceId,
				},
			}
		);

		localStorage.removeItem(getSessionName(deviceType));

		return result.data;
	};

const getSessionName = (deviceType: DeviceType) => `${deviceType.toLowerCase()}-connect-session`;
const healthCheckDeviceAsync =
	(rootUrl: string) =>
	async ({
		storeId,
		deviceId,
		deviceType,
	}: {
		storeId: number;
		deviceId: number;
		deviceType: DeviceType;
	}) => {
		const SESSION_NAME = getSessionName(deviceType);
		const sessionId = localStorage.getItem(SESSION_NAME);

		const result = await deviceAxiosClient.post<ConnectInfoType>(
			`${rootUrl}/connect/store/${storeId}/type/${deviceType}/health-check`,
			{},
			{
				params: {
					deviceId,
				},
				...(sessionId && {
					headers: {
						[SESSION_NAME]: sessionId,
					},
				}),
			}
		);

		const resultSessionId = result.headers[SESSION_NAME];
		if (resultSessionId) localStorage.setItem(SESSION_NAME, resultSessionId);

		return result.data;
	};

export const DeviceConnectApis = {
	/**
	 * admin 에서 사용하기 좋음
	 */
	getConnectInfoByTypeList: getConnectInfoListByType(rootUrl),
	disconnectDevice: disconnectDevice(rootUrl),
	healthCheckDevice: healthCheckDeviceAsync(rootUrl),
};
