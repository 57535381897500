import {
	CartAggregateType,
	CustomerReceiptRequestType,
	LabelRequestType,
	PaymentInfoType,
	PaymentMethodType,
	PrinterInfoType,
	ReceiptInfoType,
	StoreInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { useAtomValue } from 'jotai';
import { PrintReceiptUtils } from './PrintReceiptUtils';
import { useCafePrinter } from './useCafePrinter';
import { useExchangePrinter } from './useExchangePrinter';
import { useKitchenPrinter } from './useKitchenPrinter';
// import { useManualCustomerReceiptPrinter } from './useManualCustomerReceiptPrinter';
import { useCustomerReceiptPrinter, 직전영수증Atom } from './useCustomerReceiptPrinter';
import { OrderStateType } from '../PosOrder/OrderState';
import { StoreInfoState } from '../StoreInfoState';
import { useSendOpenBox } from './useSendOpenBox';
import { useCustomReceiptPrinter } from './useCustomReceiptPrinter';
import { useReceiptDdiziPrinter } from './useReceiptDdiziPrinter';
import { ReactLPIPC } from '@kinderlabs-pos/ipc/react';
import { useKiosk주문증Printer } from './useKioskPrinter';
import { KioskElectronStoreState, PosElectronStoreState } from '@kinderlabs-pos/state';

export type BaseReceiptPayloadType = {
	type: 'ORDER' | 'CANCEL';
	storeInfo: StoreInfoType;
	orderId: string;
	orderDailySeq: number;
	paymentId: PaymentInfoType['id'];
	orderList: CustomerReceiptRequestType['order'];
	aggregate: CartAggregateType;
};

/**
 * subType === "OFFICIAL" : 결제모듈을 거치는 영수증
 * subType === "UNOFFICIAL" : 결제모듈을 거치지 않는 영수증
 * subType === "FROM_ORDER" : 기존의 영수증에서
 */
export type OfficialReceiptType = {
	subType: 'OFFICIAL';
	created: Date;
	refunded?: Date;
	receipt: ReceiptInfoType;
	paymentMethod: Exclude<PaymentMethodType, 'ZEROPAY_MPM' | 'SEOULPAY_MPM' | 'CREDIT' | 'POINT'>;
};

export type UnOfficialReceiptType = {
	subType: 'UNOFFICIAL';
	created: Date;
	refunded?: Date;
	paymentAmount: number; // 분할결제시
	paymentMethod: Extract<
		PaymentMethodType,
		'CASH' | 'TRANSFER' | 'ZEROPAY_MPM' | 'SEOULPAY_MPM' | 'POINT' | 'CREDIT'
	>;
};

const use직전영수증출력 = () => {
	const 직전영수증 = useAtomValue(직전영수증Atom);
	// JM : 해당 코드는 불필요해보여서 일단 주석처리
	// const { printReceiptAsync } = useManualCustomerReceiptPrinter({
	// 	deviceType: 'POS',
	// });
	const deviceInfo = StoreInfoState.useCurDeviceInfoForOrder();
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings().data;
	const { printReceiptAsync } = useCustomerReceiptPrinter({
		deviceType: deviceInfo.deviceType,
		autoOrManual: '수동',
	});

	return async () => {
		if (직전영수증?.order) {
			printReceiptAsync({ receiptRequestData: 직전영수증 });
		}
	};
};

const use영수증수동출력 = ({ receiptType }: { receiptType: 'ORDER' | 'CANCEL' }) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const deviceInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const localSettings =
		deviceInfo.deviceType === 'POS'
			? PosElectronStoreState.usePosMainPageLocalSettings().data
			: KioskElectronStoreState.useKioskMainPageLocalSettings().data;

	const { printReceiptAsync } = useCustomerReceiptPrinter({
		deviceType: deviceInfo.deviceType,
		autoOrManual: '수동',
	});

	return async ({ order, payments }: { order: OrderStateType; payments: PaymentInfoType[] }) => {
		const receiptRequestData = PrintReceiptUtils.getCustomerReceiptData({
			order,
			storeInfo,
			payments,
			receiptType,
			deviceName: deviceInfo.label,
			barcodeModel: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
		});

		await printReceiptAsync({
			receiptRequestData,
		});
	};
};

const use간이영수증출력 = () => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const deviceInfo = StoreInfoState.useCurDeviceInfoForOrder();
	const localSettings =
		deviceInfo.deviceType === 'POS'
			? PosElectronStoreState.usePosMainPageLocalSettings().data
			: KioskElectronStoreState.useKioskMainPageLocalSettings().data;

	const { printReceiptAsync } = useCustomerReceiptPrinter({
		deviceType: deviceInfo.deviceType,
		autoOrManual: '수동',
	});

	return async ({ order, payments }: { order: OrderStateType; payments: PaymentInfoType[] }) => {
		const receiptRequestData = PrintReceiptUtils.getCustomerReceiptData({
			order,
			storeInfo,
			payments,
			is간이영수증: true,
			deviceName: deviceInfo.label,
			receiptType: order.status === 'COMPLETED' ? 'ORDER' : 'CANCEL',
			barcodeModel: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
		});

		await printReceiptAsync({
			receiptRequestData,
		});
	};
};

const use영수증자동출력 = () => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const deviceInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const localSettings =
		deviceInfo.deviceType === 'POS'
			? PosElectronStoreState.usePosMainPageLocalSettings().data
			: KioskElectronStoreState.useKioskMainPageLocalSettings().data;

	const { printReceiptAsync } = useCustomerReceiptPrinter({
		deviceType: deviceInfo.deviceType,
		autoOrManual: '자동',
	});
	return async ({ order, payments }: { order: OrderStateType; payments: PaymentInfoType[] }) => {
		const receiptRequestData = PrintReceiptUtils.getCustomerReceiptData({
			order,
			storeInfo,
			payments,
			receiptType: order.status === 'COMPLETED' ? 'ORDER' : 'CANCEL',
			deviceName: deviceInfo.label,
			barcodeModel: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
		});

		await printReceiptAsync({
			receiptRequestData,
		});
	};
};

const use키오스크주문증출력 = () => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const deviceInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const localSettings =
		deviceInfo.deviceType === 'POS'
			? PosElectronStoreState.usePosMainPageLocalSettings().data
			: KioskElectronStoreState.useKioskMainPageLocalSettings().data;

	const { printReceiptAsync } = useCustomerReceiptPrinter({
		deviceType: deviceInfo.deviceType,
		autoOrManual: '수동',
	});
	return async ({ order, payments }: { order: OrderStateType; payments: PaymentInfoType[] }) => {
		const receiptRequestData = PrintReceiptUtils.getCustomerReceiptData({
			order,
			storeInfo,
			payments,
			receiptType: order.status === 'COMPLETED' ? 'ORDER' : 'CANCEL',
			deviceName: deviceInfo.label,
			barcodeModel: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
		});

		await printReceiptAsync({
			receiptRequestData,
		});
	};
};

const useHas직전영수증 = () => {
	return !!useAtomValue(직전영수증Atom);
};

/**
 * 영수증 인쇄시 직전 영수증에 컨텍스트 저장
 */
export const PrintReceiptState = {
	useHas직전영수증,
	use직전영수증출력,
	useKitchenPrinter,
	useCafePrinter,
	useExchangePrinter,
	use영수증수동출력,
	use간이영수증출력,
	use영수증자동출력,
	useCustomReceiptPrinter,
	useSendOpenBox,
	useCustomerReceiptPrinter,
	useReceiptDdiziPrinter,
	useKiosk주문증Printer,
	utils: PrintReceiptUtils,
	LabelPrinter: {
		usePrintLabelPrinter: ({ labelPrinterList }: { labelPrinterList: PrinterInfoType[] }) => {
			labelPrinterList.length === 0;

			// JIN 라벨프린터 로직이 영수증 프린터와 다르게 좀 이상하게 되어있다.
			// 아직 ESCPOS 와 연동이 되어있지 않기 때문이다.
			// 따로 수정할 필요도 없으니 그냥 이대로 사용하도록 한다.

			return async (request: LabelRequestType) => {
				if (labelPrinterList.length === 0) return;
				await ReactLPIPC.invoke띠지출력(request);
			};
		},
	},
};
