import { PosCouponInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	PosCouponCreateType,
	PosCouponInfoListFilterType,
	PosCouponInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryClient } from '../../QueryClient';

const posCouponKeys = createQueryKeys('pos-coupon-info', {
	list: ({ param }: { param: PosCouponInfoListFilterType }) => ({
		queryKey: [param],
		queryFn: async () => {
			const res = await PosCouponInfoApis.getPage({ ...param });
			return res;
		},
	}),
	detail: (id?: number) => ({
		queryKey: [id],
		queryFn: async () => {
			if (!id) return null;
			const res = await PosCouponInfoApis.getOne(id);
			return res;
		},
	}),
});

const useCreateCouponInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<PosCouponInfoType, 'id' | 'couponStatusStatistics'>) => {
			await PosCouponInfoApis.createInfo(input);
		},
		{
			onSuccess: invalidateQueries,
		}
	);
};

const useCreateCoupon = () => {
	return QueryClient.useMutation(
		async ({ input, stock }: { input: PosCouponCreateType; stock: number }) => {
			await PosCouponInfoApis.createCoupon({ couponInfo: input, stock });
		},
		{
			onSuccess: invalidateQueries,
		}
	);
};

const useUpdateCouponInfo = () => {
	const queryClient = QueryClient.useQueryClient();

	return QueryClient.useMutation(
		async (input: PosCouponInfoType) => {
			await PosCouponInfoApis.updateInfo(input);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: posCouponKeys.detail._def });
			},
		}
	);
};

const UserDeletePosCouponInfoList = () => {
	return QueryClient.useMutation(
		async (input: number[]) => {
			await PosCouponInfoApis.deleteInfoList({ idList: input });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () =>
	QueryClient.origin.invalidateQueries({ queryKey: posCouponKeys._def });

export const PosCouponInfoState = {
	keys: posCouponKeys,
	useCreate: useCreateCouponInfo,
	invalidateQueries: invalidateQueries,
	useCouponCreate: useCreateCoupon,
	useUpdate: useUpdateCouponInfo,
	useDelete: UserDeletePosCouponInfoList,
};
