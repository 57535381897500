import { ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import { sleep } from '@kinderlabs-pos/shared-util';
import { AdminPosInfoState } from '../Admin/AdminPosInfoState';
import { OrderState, OrderStateType } from '../PosOrder/OrderState';
import { StoreInfoState } from '../StoreInfoState';
import { DeviceType } from '@kinderlabs-pos/shared-data-type';
import { KioskElectronStoreState, PosElectronStoreState } from '../ElectronStoreState';

export const useCafePrinter = ({
	storeId,
	deviceType,
}: {
	storeId: number;
	deviceType: DeviceType;
}) => {
	const { receiptPrinterList: RPList, printMode } = StoreInfoState.useCurDeviceInfoForOrder();
	const deviceInfoList = AdminPosInfoState.usePosAndKioskSelectorList({ storeId });
	const localSettings =
		deviceType === 'POS'
			? PosElectronStoreState.usePosMainPageLocalSettings().data
			: KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	return {
		printCafe주문서Async: async ({
			order,
			주방주문증크게 = false,
			취소주문증인지 = false,
		}: {
			order: OrderStateType;
			주방주문증크게?: boolean;
			취소주문증인지?: boolean;
		}) => {
			const cafeReceipt = OrderState.cart.utils.getOrderListFromCart(order.cart.lines, 'cafe');
			const deviceInfo = deviceInfoList.find((d) => d.device.id === order.posId);

			// ESCPOS 든 BIXOLONSDK 든 영수증 프린트 하면 안 되는 상황
			if (!cafeReceipt.length) {
				return;
			}

			const CandidatePrinterInfos = RPList.filter(
				(p) => p.hardwareType.usageType === 'CAFE' && p.isActive
			);

			for (const printerInfo of CandidatePrinterInfos) {
				for (let i = 0; i < printerInfo.quantity; i++) {
					printMode === 'BIXOLONSDK' && (await sleep(500));
					await ReactRPIPC.invoke프론트영수증출력({
						hardwareInfo: {
							deviceType: deviceType,
							printerInfo: printerInfo,
							printMode,
							영수증너비: localSettings ? localSettings.영수증너비 : deviceType === 'POS' ? 42 : 48,
						},
						order: cafeReceipt,
						orderDeviceName: deviceInfo ? `[${deviceInfo.type}] ${deviceInfo.device.name}` : '-',
						foodReceiptId: order.dailySeq ?? 0,
						orderDate: new Date(),
						largeFont: 주방주문증크게,
						isCancel: 취소주문증인지,
					});
				}
			}
		},
	};
};

// JM : BXLN 레거시 코드 백업
// if (RPList.length === 0) {
//   // FIXME : JIN :남양주에선 매표소에서 선불권을 뽑고 있었다고 하네요.
//   // 가드를 넣었습니다. 추후 포스-프린터 연결 설정 바꾸고 나면 제대로 해주어야 할 듯
//   const is남양주의메인프론트포스 = curPosInfo.id === 52 || curPosInfo.id === 53;

//   if (is남양주의메인프론트포스 || curPosInfo.posType === 'KITCHEN') {
//     // JIN : || 1 를 안 해도 되는데 테스트시간이 모자라서 마이그레이션 가드 추가
//     // JIN 추가 내용: BIXOLONSDK 일때만 이것을 신경쓰면 됨
//     for (let i = 0; i < (curPosInfo.numberOfFrontReceipts || 1); i++) {
//       await ReactPosIPC.hardware.invoke프론트영수증출력({
//         deviceType,
//         printMode: 'BIXOLONSDK',
//         deviceNum: 'ReceiptPrinter1',
//         order: cafeReceipt,
//         foodReceiptId: order.dailySeq,
//         orderDate: new Date(),
//         deviceInfo: undefined, // 이거 BIXLONSDK 일땐 undefined 인듯
//       });
//     }
//   }
// }
