import { WaiverApis } from '@kinderlabs-pos/apis/waivers';
import { GuestVisitType, Pageable } from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { atom, useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { authState } from '../AuthState';
import { QueryClient } from '../QueryClient';
import { StoreInfoState } from '../StoreInfoState';

type GuestVisitListParamsType = {
	storeIdList?: number[];
	page: number;
	size?: number;
	search?: string;
	from: Dayjs;
	to: Dayjs;
	sort?: Pageable['sort'];
	status?: GuestVisitType['status'] | GuestVisitType['status'][];
};

const guestVisitKeys = createQueryKeys('guest-visit', {
	// ADMIN
	list: ({ params }: { params: GuestVisitListParamsType }) => {
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);
		return {
			queryKey: [params],
			queryFn: async () => {
				return await WaiverApis.searchGuestVisits({
					storeIdList: params.storeIdList || storeIdListForManager,
					search: params.search,
					statusList: params.status
						? Array.isArray(params.status)
							? params.status
							: [params.status]
						: [],
					pageable: Pageable.getPageable(params),
					from: params.from.startOf('d'),
					to: params.to.endOf('d'),
				});
			},
		};
	},
	// POS
	infinite: ({ params }: { params: Omit<GuestVisitListParamsType, 'page' | 'size'> }) => ({
		queryKey: [
			{
				storeIdList: params.storeIdList,
				search: params.search,
				status: params.status,
				from: params.from.format('YYYY-MM-DD HH:mm:ss'),
				to: params.to.format('YYYY-MM-DD HH:mm:ss'),
				sort: params.sort,
			},
		],
		queryFn: async ({ pageParam }: { pageParam?: { startCursorId: string | number } }) => {
			const res = await WaiverApis.searchGuestVisitInfinite({
				storeIdList: params.storeIdList,
				cursorId: pageParam?.startCursorId,
				search: params.search,
				statusList: params.status
					? Array.isArray(params.status)
						? params.status
						: [params.status]
					: [],
				pageable: Pageable.getPageable({ page: 0, size: 20, sort: params.sort }),
				from: params.from,
				to: params.to,
			});

			return {
				content: res.content,
				isLast: !res.hasMore,
				startCursorId: res.startCursorId,
			};
		},
	}),
	detail: (guestVisitId?: number) => ({
		queryKey: [guestVisitId],
		queryFn: async () => {
			if (!guestVisitId) return null;
			const res = await WaiverApis.getGuestVisitDetail({ guestVisitId });
			return res;
		},
	}),
	/**
	 * Waiver, DID 에서 사용
	 */
	todayInstore: ({ statusList }: { statusList?: GuestVisitType['status'][] }) => {
		const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
		return {
			queryKey: [storeId, statusList],
			queryFn: async () => {
				const res = await WaiverApis.getAllGuestVisitsToday({ storeId, statusList });
				return res;
			},
		};
	},
	/**
	 * ADMIN 을 위한 기능이다.
	 */
	todayAllStore: ({
		storeIdList,
		statusList,
		date,
	}: {
		storeIdList?: number[];
		statusList?: GuestVisitType['status'][];
		date: Dayjs;
	}) => ({
		queryKey: [storeIdList, statusList, date],
		queryFn: async () => {
			const allGvList = await WaiverApis.getAllGuestVisitsInAllStoreToday({
				statusList,
				date,
				storeIdList,
			});
			return {
				allGvList,
			};
		},
	}),
});

const useTodayWaitingGvList = ({ refetchInterval }: { refetchInterval?: number }) => {
	const { data } = useQuery({
		...guestVisitKeys.todayInstore({}),
		refetchOnWindowFocus: true,
		refetchInterval: refetchInterval ?? 30 * 1000,
	});

	const gvList = data ?? [];
	const waitingGv = gvList.filter((gv) => gv.status === 'WAITING');
	return waitingGv;
};

const useTodayCompletedGv = ({ refetchInterval }: { refetchInterval?: number }) => {
	const { data } = useQuery({
		...guestVisitKeys.todayInstore({}),
		refetchOnWindowFocus: true,
		refetchInterval: refetchInterval ?? 30 * 1000,
	});

	const gvList = data ?? [];
	const waitingGv = gvList.filter((gv) => gv.status === 'WAITING');

	const mappedResult = useMemo(() => {
		const tempMap: Map<number, GuestVisitType> = new Map();

		waitingGv.forEach((gv) => {
			if (gv.posId) tempMap.set(gv.posId, gv);
		});

		return tempMap;
	}, [waitingGv]);

	return {
		list: gvList,
		// PosId - 처리중인 GuestVisit Map
		map: mappedResult,
	};
};

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: guestVisitKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: guestVisitKeys._def });
};

const invalidateDetailQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: guestVisitKeys.detail._def });
	QueryClient.origin.invalidateQueries({ queryKey: guestVisitKeys.detail._def });
};

const recentVisitIdSetCalledByOtherPos = atom<number[]>([]);

export const GuestVisitQueryState = {
	keys: guestVisitKeys,
	useTodayCompletedGv,
	useTodayWaitingGvList,
	invalidateQueries,
	invalidateDetailQueries,
	recentVisitIdSetCalledByOtherPos,
};
