import { DdiziInfoType } from '../../admin/DdiziInfoType';
import { ExchangeInfoType } from '../../admin/ExchangeInfoType';
import { ProductDiscountInfoType } from '../../admin/ProductDiscountInfoType';
import { ProductInfoType } from '../../admin/ProductInfoType';
import { MembershipTicketType } from '../../UsageType';

export const CartLineTicketTypeArray = [
	'VAUNCE_APP_MUT',
	'VAUNCE_APP_CMT',
	'VAUNCE_APP_OTT',
	'VAUNCE_APP_DISCOUNT',
	'VAUNCE_APP_FREE_COUPON',
] as const;
export const CartLineTypeConverter: Record<MembershipTicketType, CartLineTicketType> = {
	MULTIUSE: 'VAUNCE_APP_MUT',
	COMMUTATION: 'VAUNCE_APP_CMT',
	ONETIME: 'VAUNCE_APP_OTT',
	DISCOUNT: 'VAUNCE_APP_DISCOUNT',
	FREECOUPON: 'VAUNCE_APP_FREE_COUPON',
};
export type CartLineTicketType = (typeof CartLineTicketTypeArray)[number];

export const CartLineTypeArray = [
	'PRODUCT',
	// 'PRODUCT_OPTION',
	// 임의 상품 / 임의 상품 할인 타입
	'CUSTOM',
	'DISCOUNT',
	'CUSTOM_DISCOUNT',
	// 할인쿠폰, 일회권, 다회권 등 티켓
	...CartLineTicketTypeArray,
	// 정산, 추가요금
	'EXCESS_CHARGE',
	// 상품옵션
	'OPTION',
	// 세트 상품
	'SET_PRODUCT',
	// 세트 상품옵션
	'SET_OPTION',
	// 진동벨
	'BELL',
	'KL_COUPON_PRODUCT',
	'KL_COUPON_DISCOUNT',
	'ONLINE_TICKET_ONOFFMIX',
] as const;
export type CartLineType = (typeof CartLineTypeArray)[number];
export const CartLineTypeLabel: Record<CartLineType, string> = {
	PRODUCT: '상품',
	CUSTOM: '임의 상품',
	DISCOUNT: '할인',
	CUSTOM_DISCOUNT: '임의 할인',
	VAUNCE_APP_MUT: '다회권',
	VAUNCE_APP_CMT: '정기권',
	VAUNCE_APP_OTT: '일회권',
	VAUNCE_APP_DISCOUNT: '할인권',
	VAUNCE_APP_FREE_COUPON: '증정권',
	EXCESS_CHARGE: '초과 요금',
	OPTION: '옵션',
	SET_PRODUCT: '세트상품',
	SET_OPTION: '세트옵션',
	BELL: '진동벨',
	KL_COUPON_PRODUCT: 'POS 증정 쿠폰',
	KL_COUPON_DISCOUNT: 'POS 할인 쿠폰',
	ONLINE_TICKET_ONOFFMIX: '온라인티켓',
};

export type CartLineInfoType = {
	id: string; // string
	type: CartLineType;
	quantity: number;
	name: string;
	price: number;

	productInfoId?: number; // 상품 사용시 ID

	productInfo: {
		category: string;
		isForKitchen: boolean;

		ddiziInfo?: DdiziInfoType;
		exchangeInfo?: ExchangeInfoType;
	};
	productAdditionalInfo: {
		rawPrice: number;
		supplyPrice: number;
		taxFree: boolean;
	};
	productDiscountInfo?: Pick<ProductDiscountInfoType, 'value' | 'type'>;
	targetCartLineId?: string;
	couponId?: string;
	imageUrl?: string; // 백엔드엔 쓰이지도 않고 키오스크에서만 살짝 사용

	i18n?: {
		eng?: string;
		jpn?: string;
		chn?: string;
	};
};

export const defaultProductAdditionalInfo: CartLineInfoType['productAdditionalInfo'] = {
	taxFree: false,
	rawPrice: 0,
	supplyPrice: 0,
};

export const getProductAdditionalInfo = (
	productInfo: ProductInfoType
): CartLineInfoType['productAdditionalInfo'] => ({
	taxFree: productInfo.taxFree || false,
	rawPrice: productInfo.rawPrice || 0,
	supplyPrice: productInfo.supplyPrice || 0,
});

export interface CartLineProductInfoType extends Omit<CartLineInfoType, 'productDiscountInfo'> {
	type: 'PRODUCT' | 'SET_PRODUCT';
	productInfoId: number;
	targetCartLineId: undefined;
	sessionInfo?: {
		sessionId: string;
		sessionBoardIndex: number;
	};
}

export interface CartLineCustomProductInfoType
	extends Omit<CartLineInfoType, 'productDiscountInfo' | 'productInfoId'> {
	type: 'CUSTOM';
	productInfoId: undefined;
	targetCartLineId: undefined;
}

export interface CartLineProductOptionInfoType
	extends Omit<CartLineInfoType, 'productDiscountInfo'> {
	type: 'OPTION';
	ticketUsageId: undefined;
	targetCartLineId: string;
}

export interface CartLineDiscountInfoType extends Omit<CartLineInfoType, 'productInfoId'> {
	type: 'DISCOUNT';
	productInfoId: undefined;
	productDiscountInfo: Required<CartLineInfoType['productDiscountInfo']>;
	targetCartLineId: string;
}

export interface CartLineCustomDiscountInfoType extends Omit<CartLineInfoType, 'productInfoId'> {
	type: 'CUSTOM_DISCOUNT';
	productInfoId: undefined;
	productDiscountInfo: Required<CartLineInfoType['productDiscountInfo']>;
	targetCartLineId: string;
}

export interface CartLineTicketUsageInfoType extends Omit<CartLineInfoType, 'productInfoId'> {
	type: CartLineTicketType;
	productInfoId: undefined;
	ticketUsageInfo: {
		name: string;
		ticketUsageId: string; // 티켓 사용시 ID
		ticketId: number;
		ticketInfoId: string; // 품목 집계에 필요 = ticketInfoId
		memberId: number;
		userName: string;
		userTelephone: string;
	};
	quantity: 1;
}

export interface CartLineMemberTicketUsageInfoType
	extends Omit<CartLineTicketUsageInfoType, 'productDiscountInfo'> {
	type: 'VAUNCE_APP_MUT' | 'VAUNCE_APP_CMT' | 'VAUNCE_APP_OTT';
	productInfoId: undefined;
	targetCartLineId: undefined;
}

export interface CartLineDiscountCouponUsageInfoType extends CartLineTicketUsageInfoType {
	type: 'VAUNCE_APP_DISCOUNT';
	productInfoId: undefined;
	targetCartLineId: string;
	productDiscountInfo: Required<CartLineInfoType['productDiscountInfo']>;
}

export interface CartLineExcessChargeInfoType
	extends Omit<CartLineInfoType, 'productDiscountInfo' | 'productInfoId'> {
	type: 'EXCESS_CHARGE';
	productInfoId?: number;
	ddiziUsageId: string; // 띠지 사용시 ID
	quantity: number;
}

export interface CartLineBellInfoType
	extends Omit<CartLineInfoType, 'productDiscountInfo' | 'productInfoId'> {
	type: 'BELL';
	productInfoId: undefined;
	bellNumber: string;
	ticketUsageId: undefined;
	productDiscountInfo: undefined;
	targetCartLineId: undefined;
	quantity: 1;
}

export interface CartLineOnlineTicketOnoffmixInfoType
	extends Omit<CartLineInfoType, 'productDiscountInfo'> {
	type: 'ONLINE_TICKET_ONOFFMIX';
	// productInfoId: undefined;
	productDiscountInfo: undefined;
	targetCartLineId: undefined;
	quantity: 1;
	ticketUsageInfo: {
		ticketUsageId: string;
		name: string;
		userName: string;
		userTelephone: string;
	};
}

export type CartLineKlPosProductInfoType = Omit<
	CartLineInfoType,
	'targetCartLineId' | 'couponId' | 'productInfoId' | 'productDiscountInfo'
> & {
	type: 'KL_COUPON_PRODUCT';
	productInfoId: undefined;
	targetCartLineId: undefined;
	couponId: string;
	quantity: 1;
};

export type CartLineKlPosDiscountInfoType = Omit<
	CartLineInfoType,
	'couponId' | 'productInfoId' | 'productDiscountInfo'
> & {
	type: 'KL_COUPON_DISCOUNT';
	productInfoId: undefined;
	targetCartLineId: string;
	productDiscountInfo: Required<CartLineInfoType['productDiscountInfo']>;
	couponId: string;
	quantity: 1;
};

// export interface CartLineCustomProductInfoType
// 	extends Omit<CartLineInfoType, 'productDiscountInfo'> {
// 	type: 'CUSTOM';
// 	productInfoId: string;
// 	targetCartLineId: undefined;
// }

export type CartLineInputType = Omit<CartLineInfoType, 'quantity'>;
