import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

/**
 * 이거 완전히 뜯어고쳐야겠는데?
 */
// 백엔드나 파일에서 setting 을 덮어써서 atomWithDefault 로 만들 것
const posSettingAtom = atomWithStorage('pos/settings', {
	autoReceiptPrintOn: false,
	vaultcashCheckOnOpening: true,
	vaultcashWithdrawalAlertAmount: 0,
	parkingLotUrl: '',
	posFileUrl: '',
});

export const PosState = {
	settings: posSettingAtom,
};
