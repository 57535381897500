import { vaunceAppColors } from '@kinderlabs-pos/shared-const';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
	Badge,
	Box,
	FormControl,
	FormControlLabel,
	InputLabel,
	InputLabelProps,
	NativeSelect,
	Stack,
	styled,
	Typography,
} from '@mui/material';
import ticketImage from 'assets/vaunce/image/multi_large.png';
import React, { useEffect, useRef, useState } from 'react';
import { PageHeader } from '../../components/PageHeader';
import { RoundBox } from '../../components/RoundBox';
import { AgreeAllInfoDialog } from './AgreeAllInfoDialog';

import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { MyInfoBodyType, PremiumCollection } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import { NicePayPage } from '../../components/NicePay';
import { VaunceCheckBox } from '../../components/VaunceCheckBox';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { mutNoticeFirstContent, mutNoticeSecondContent } from './MutDialogDummyData';
import { purchaseLog } from '../../utils/TechLabsLogUtils';

export interface IMultiTicketPageProps {}
export const MultiTicketPage: React.FC<IMultiTicketPageProps> = ({}) => {
	const [checked, setChecked] = React.useState([false, false]);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([event.target.checked, event.target.checked, event.target.checked]);
	};

	const handleChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([event.target.checked, checked[1], checked[2]]);
	};

	const handleChangeSecond = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([checked[0], event.target.checked, checked[2]]);
	};

	const myInfo = VaunceQueryState.useMyInfoQuery();
	const multiUseTicketInfo = VaunceQueryState.useTicketInfoQuery('PREMIUM_MULTI');
	const [centerId, setCenterId] = useState<number>(0);
	const [priceDisplayName, setPriceDisplayName] = useState<string>('unSelected');
	const [count, setCount] = useState<number>(5);
	const [price, setPrice] = useState<string>('');
	const [webTicket, setWebTicket] = useState<string>('');

	const ref = useRef<any>();

	const [hashString, setHashString] = useState<string>('');
	const [ediDate, setEdiDate] = useState<string>('');
	const [payModel, setPayModel] = useState<any>({});

	function getCenterName(displayName: string) {
		return displayName.split('_')[3];
	}

	function getCenter(centerId: number) {
		const findCenter = multiUseTicketInfo.listCenters.find((elem) => elem.centerId === centerId);
		const newCenter = `멀티_${findCenter?.centerDisplay}_${count}회권_${getCenterName(
			priceDisplayName
		)}`;
		return newCenter;
	}

	const handlePay = async () => {
		if (!myInfo) throw Error();

		const payDataForm = new FormData();
		payDataForm.append('price', price.replace(',', ''));
		payDataForm.append('goodsName', getCenter(centerId));
		payDataForm.append('userId', myInfo.userId);
		payDataForm.append('webTicketMasterListIdx', webTicket);

		const data = await VaunceAppApis.postNicePayAsync(payDataForm);
		if (data) {
			setHashString(data.hashString);
			setEdiDate(data.ediDate);
			setPayModel(data.payRequestModel);
			purchaseLog(centerId, '다회권');
		}
	};

	useEffect(() => {
		if (hashString && ref.current) ref.current.submit();
	}, [ref, hashString]);

	const children = (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<ContentBox>
				<CheckLabelBox
					sx={{ fontSize: '1.5em' }}
					label={<Typography variant='h5'>[필수] 다회권 구매에 동의</Typography>}
					control={
						<VaunceCheckBox
							checked={checked[0]}
							onChange={handleChangeFirst}
							size={'small'}
						/>
					}
				/>
			</ContentBox>

			<ContentBox>
				<CheckLabelBox
					sx={{ fontSize: '16px' }}
					label={<Typography variant='h5'>[필수] 결제, 환불 및 사용 유의사항에 동의</Typography>}
					control={
						<VaunceCheckBox
							checked={checked[1]}
							onChange={handleChangeSecond}
							size={'small'}
						/>
					}
				/>
			</ContentBox>
		</Box>
	);

	return (
		<Stack
			height={'100%'}
			direction={'column'}>
			<PageHeader title='다회권' />
			<Stack
				height={'100%'}
				overflow={'auto'}
				direction={'column'}
				textAlign={'center'}
				alignItems={'center'}>
				<Box marginBottom={'1.5rem'}>
					<img
						src={ticketImage}
						width={70}
					/>
				</Box>

				<Badge
					sx={{
						'& .MuiBadge-badge': {
							right: -8,
						},
					}}
					badgeContent={<PluseOne />}>
					<Stack
						direction={'row'}
						alignItems={'center'}>
						<Typography
							variant='h1'
							color={'primary'}>
							5
						</Typography>
						<Typography
							variant='h2'
							color={'primary'}>
							회권
						</Typography>
					</Stack>
				</Badge>

				<Typography
					variant='h2'
					color='primary'>
					총 6회권 증정
				</Typography>
				<Typography
					variant='h4'
					color={'#707070'}>
					유효기간 6개월
				</Typography>

				{/* <RoundBox marginTop={'3em'}>
					<Typography variant='h4'>구매 시, 지정 센터에서만 사용 가능</Typography>
				</RoundBox> */}

				<RoundBox
					marginTop={'3em'}
					sx={{ textAlign: 'left' }}>
					<Typography variant='h5'>- 구매 시 지정한 센터에서만 사용 가능합니다.</Typography>
					<Typography variant='h5'>- 세종센터는 오픈 기념 10 회권 30% 할인 중 입니다.</Typography>
					<Typography variant='h5'>
						- 세종센터 선택 시 10회권으로 구매되며 유효기간은 1 년 입니다.( 이벤트 기간: 3/28-4/3)
					</Typography>
				</RoundBox>
				<Stack
					spacing={3}
					width={'100%'}>
					<PassSelectGroup
						centerId={centerId}
						priceDisplayName={priceDisplayName}
						count={count}
						setCenterId={setCenterId}
						setPriceDisplayName={setPriceDisplayName}
						setPrice={setPrice}
						setWebTicket={setWebTicket}
						data={multiUseTicketInfo}
						multi
						myInfo={myInfo}
					/>

					<FormControl
						fullWidth
						sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.42)' }}>
						<Stack direction={'row'}>
							<FormControlLabel
								label={<Typography variant='h5'>[필수] 모두 동의합니다.</Typography>}
								control={
									<VaunceCheckBox
										checked={checked[0] && checked[1]}
										onChange={handleChangeAll}
									/>
								}
								sx={{ mr: 'auto', fontSize: '1.4em' }}
							/>
							<AgreeAllInfomationBtn
								noticeFirstContent={mutNoticeFirstContent}
								noticeSecondContent={mutNoticeSecondContent}
							/>
						</Stack>
						{children}
					</FormControl>

					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}>
						<Typography variant='h4'>결제 금액</Typography>
						{price && (
							<Typography
								fontSize={'26px'}
								fontWeight={500}>
								{price}원
							</Typography>
						)}
					</Stack>

					{/* TODO 추후 formik 적용필요 */}
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={2}
						p={1}>
						<RoundedSubmitButton
							fullWidth
							onClick={() => {
								handlePay();
								setIsSubmitting(true);
								setTimeout(() => {
									setIsSubmitting(false);
								}, 3000);
								loggingEvent('다회권 구매', 'submit', 'mutTicketBuy');
							}}
							disabled={
								!(
									checked[0] &&
									checked[1] &&
									price &&
									centerId &&
									priceDisplayName !== 'unSelected'
								)
							}
							isSubmitting={isSubmitting}
							text={'구매하기'}
						/>
					</Stack>
					<>
						<NicePayPage
							payRef={ref}
							payModel={payModel}
							ediDate={ediDate}
							hashString={hashString}
						/>
					</>
				</Stack>
			</Stack>
		</Stack>
	);
};

interface IPassSelectGroupProps {
	centerId: number;
	priceDisplayName: string;
	count: number;
	setCenterId: React.Dispatch<React.SetStateAction<number>>;
	setPriceDisplayName: React.Dispatch<React.SetStateAction<string>>;
	setPrice: React.Dispatch<React.SetStateAction<string>>;
	setWebTicket: React.Dispatch<React.SetStateAction<string>>;
	data: PremiumCollection;
	multi?: boolean;
	myInfo: MyInfoBodyType | undefined;
}
export const PassSelectGroup: React.FC<IPassSelectGroupProps> = ({
	centerId,
	priceDisplayName,
	count,
	setCenterId,
	setPriceDisplayName,
	setPrice,
	setWebTicket,
	data,
	multi,
	myInfo,
}) => {
	function getCenterName(displayName: string) {
		return displayName.split('_')[3] + ' ' + displayName.split('_')[2];
	}

	return (
		<>
			<FormControl
				fullWidth
				sx={{ pt: 1 }}>
				<InputLabel variant='standard'>
					<Typography
						variant='h5'
						fontWeight={400}>
						선택1 (이용센터)
					</Typography>
				</InputLabel>
				<NativeSelect
					sx={{ fontSize: '16px', fontWeight: 500 }}
					value={centerId}
					onChange={(event) => {
						setCenterId(parseInt(event?.target.value));
						setPriceDisplayName('unSelected');
						setPrice('');
					}}>
					<option value={'unSelected'}>{'선택'}</option>
					{data &&
						data.listCenters.map((centerInfo) => (
							<option
								value={centerInfo.centerId}
								key={centerInfo.centerId}>
								{centerInfo.centerDisplay}
							</option>
						))}
				</NativeSelect>
			</FormControl>

			<FormControl
				fullWidth
				sx={{ pt: 1 }}>
				<InputLabel variant='standard'>
					<Typography variant='h5'> 선택2 (권종)</Typography>
				</InputLabel>
				<NativeSelect
					sx={{ fontSize: '16px', fontWeight: 500 }}
					value={priceDisplayName}
					onChange={(event) => {
						setPriceDisplayName(event.target.value);
						const findCenter = data.listCenters.find((elem) => elem.centerId === centerId);
						const findPerson = findCenter?.listPerson.find(
							(test) => test.listPrice[0].priceDisplayName === event.target.value
						);

						if (findPerson) {
							setPrice(findPerson.listPrice[0].price);
							setWebTicket(findPerson.listPrice[0].web_ticket_master_list_idx);
						}
					}}>
					<option value={'unSelected'}>{'선택'}</option>
					{data.listCenters
						.find((elem) => elem.centerId === centerId)
						?.listPerson.map((elem) =>
							elem.listPrice[0].web_ticket_master_list_idx === '365' ||
							elem.listPrice[0].web_ticket_master_list_idx === '366' ? (
								myInfo &&
								(myInfo.uid === 1583133 || myInfo.uid === 1570912 || myInfo.uid === 52550) ? (
									<option
										value={elem.listPrice[0].priceDisplayName}
										key={elem.personTypeId}>
										{getCenterName(elem.listPrice[0].priceDisplayName)}
									</option>
								) : (
									<></>
								)
							) : (
								<option
									value={elem.listPrice[0].priceDisplayName}
									key={elem.personTypeId}>
									{getCenterName(elem.listPrice[0].priceDisplayName)}
								</option>
							)
						)}
				</NativeSelect>
			</FormControl>

			{/* <FormControl
				fullWidth
				sx={{ pt: 1 }}>
				<InputLabel variant='standard'>
					{multi ? (
						<Typography variant='h5'> 선택3 (횟수)</Typography>
					) : (
						<Typography variant='h5'> 선택3 (기간)</Typography>
					)}
				</InputLabel>
				<NativeSelect sx={{ fontSize: '16px', fontWeight: 500 }}>
					{
						<option value={count}>
							{count} {multi ? '회권' : '개월 정기권'}
						</option>
					}
				</NativeSelect>
			</FormControl> */}
		</>
	);
};

interface AgreeAllInfomationBtnProps {
	otherContent?: string[];
	noticeFirstContent: string[];
	noticeSecondContent: string[];
}
export const AgreeAllInfomationBtn: React.FC<AgreeAllInfomationBtnProps> = ({
	noticeFirstContent,
	noticeSecondContent,
	otherContent,
}) => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<AgreeAllInfoDialog
					open={open}
					onClose={closeDialog}
					noticeFirstContent={noticeFirstContent}
					noticeSecondContent={noticeSecondContent}
					otherContent={otherContent}
				/>
			)}>
			{(openDialog) => (
				<DetailBox onClick={openDialog}>
					<Typography
						variant='h6'
						color='primary'>
						자세히 보기
					</Typography>
					<ArrowForwardIosIcon
						sx={{ color: `${vaunceAppColors.lightPink}` }}
						style={{ height: '18px', width: '18px' }}
					/>
				</DetailBox>
			)}
		</WithDialog>
	);
};

type IPiknInputLabelProps = InputLabelProps;
export const PiknInputLabel: React.FC<IPiknInputLabelProps> = ({ ...others }) => {
	return <StyledInputLabel {...others} />;
};

const PluseOne = () => {
	return (
		<PluseOneBox>
			<Typography
				variant='h4'
				color={'#FFF'}>
				+1
			</Typography>
		</PluseOneBox>
	);
};

const PluseOneBox = styled(Box)(({ theme }) => ({
	borderRadius: '50%',
	backgroundColor: `${theme.palette.primary.main}`,
	padding: '3.5px 6px',
}));

const CheckLabelBox = styled(FormControlLabel)(({ theme }) => ({
	marginRight: 'auto',
}));

const ContentBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
	fontWeight: 400,
	fontSize: '0.9rem',
	color: '#000',
}));

const DetailBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
}));
