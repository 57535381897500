import { DayCalendarFilterOptionType } from '../CalendarFilterOptionType';
import { Pageable } from '../PageType';
import { GuestUserType } from '../users/GuestUserType';
import { VaunceCouponInfoType } from '../vaunce/CouponType';
import { MembershipType } from '../vaunce/MyInfoType';
import { GuestCallType } from './GuestCallType';

export type AdminGuestVisitMgFilterType = {
	storeIdList?: number[];
	page: number;
	size?: number;
	search?: string;
	calendarOption: DayCalendarFilterOptionType;
	sort?: Pageable['sort'];
	status?: GuestVisitType['status'];
};

export const GuestVisitStatusTypeArray = ['WAITING', 'COMPLETED', 'CANCELED', 'MERGED'] as const;
type GuestVisitStatusType = (typeof GuestVisitStatusTypeArray)[number];
export const GuestVisitStatusTypeLabel: Record<GuestVisitStatusType, string> = {
	WAITING: '대기 중',
	COMPLETED: '응대 완료',
	CANCELED: '응대 취소',
	MERGED: '병합됨',
};

export const canReturnToWaiting: Record<GuestVisitStatusType, boolean> = {
	WAITING: false,
	COMPLETED: true,
	CANCELED: true,
	MERGED: true,
};

export const canRerun: Record<GuestVisitStatusType, boolean> = {
	WAITING: true,
	COMPLETED: false,
	CANCELED: false,
	MERGED: true,
};
export const canCancel: Record<GuestVisitStatusType, boolean> = {
	WAITING: true,
	COMPLETED: false,
	CANCELED: false,
	MERGED: false,
};
export const canMerge: Record<GuestVisitStatusType, boolean> = {
	WAITING: true,
	COMPLETED: false,
	CANCELED: true,
	MERGED: false,
};
export const canCall: Record<GuestVisitStatusType, boolean> = {
	WAITING: true,
	COMPLETED: false,
	CANCELED: false,
	MERGED: false,
};

export const GuestVisitStatusTypeColor: Record<
	GuestVisitStatusType,
	'info' | 'error' | 'warning' | 'success' | 'primary' | 'inherit'
> = {
	WAITING: 'primary',
	COMPLETED: 'success',
	CANCELED: 'error',
	MERGED: 'warning',
};

export interface GuestVisitMemberInfoType {
	memberId: number;
	name: string;
	telephone: string;
}

export interface GuestVisitType {
	id: number;
	status: GuestVisitStatusType;
	posId?: number;
	storeId: number;
	sunbun: number;
	calls?: GuestCallType[];
	memberInfo?: GuestVisitMemberInfoType;
	users: GuestUserType[];
	createdDate: Date;
	createdDateTime: Date;
	canceledDateTime: Date | null;
	//Noah
	visitCount?: { totalCount: number; normalCount: number; childCount: number };
	mobileUrl: string;
}

export type GuestVisitSummaryType = {
	storeId: number;
	enterpriseId: number;
	name: string;
	telephone: string;
	visitedDate: Date;
	orderValue: number;
	ticketCount: number;
	isOutlier: boolean;
};

export interface GuestVisitStatisticsType {
	storeId: number;
	storeName: string;
	waiting: number;
	processing: number;
	completed: number;
	lastCompletedSunbun: number;
}

export interface GuestVisitTypeWithMemberInfo {
	guestVisit: GuestVisitType;
	vaunceMemberInfo?: VaunceMemberInfoType;
	couponInfoList: VaunceCouponInfoType[];
}

export const isVaunceMemberInfoSNS: Record<
	NonNullable<VaunceMemberInfoType['providerType']>,
	boolean
> = {
	KAKAO: true,
	NAVER: true,
	LOCAL: false,
};

// 회원 검색시 사용하는 타입.
export interface VaunceMemberInfoType {
	id: number; // 숫자 ID
	userId: string; // 이메일
	username: string; // 유저 이름
	remainingPoint: number;
	telephone: string;
	vaunceMemberGrade: MembershipType;
	couponInfoList: VaunceCouponInfoType[];
	providerType?: 'KAKAO' | 'NAVER' | 'LOCAL';
}

export const getArrangedAppUserId = (
	provider: VaunceMemberInfoType['providerType'],
	userId: VaunceMemberInfoType['userId']
): VaunceMemberInfoType['userId'] => {
	switch (provider) {
		case 'LOCAL':
			return userId;
		default:
			return `${provider} 가입 회원`;
	}
};
